<template>
   <div  v-if="isLoadingStart">
    <div class="d-flex justify-content-center mb-1">
      <b-spinner label="Loading..." />
    </div>
  </div>
  <validation-observer  v-else #default="{ handleSubmit }" ref="ownershipRules">
    <b-form class="mt-1" @submit.prevent="handleSubmit(addEvaluationRequest)" @reset.prevent="resetForm">
      <b-row>
        <b-col cols="12" md="12">
          <b-alert v-model="showMessage" dismissible :variant="messageType">
            <div class="alert-body">
              <feather-icon class="mr-25" icon="InfoIcon" />
              <span class="ml-25" v-html="message" />
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12">
          <b-alert variant="info" :show="features.length == 0" class="mb-0">
            <div class="alert-body">
              <feather-icon icon="InfoIcon" class="mr-50" />
              يمكنك المرور الى المرحلة التالية.

            </div>
          </b-alert>
          <b-alert v-model="showMessage" dismissible :variant="messageType">
            <div class="alert-body">
              <feather-icon class="mr-25" icon="InfoIcon" />
              <span class="ml-25" v-html="message" />
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row v-for="(group, index) in features" :id="group.id" :key="index">
        <b-col v-if="group.display_name !== 'معلومات الملكية'" cols="12" md="12" lg="12">
          <div class="d-flex">
            <feather-icon icon="GlobeIcon" size="19" />
            <h4 class="mb-0 ml-50">
              {{ group.display_name }}
            </h4>
          </div>
        </b-col>
        <b-col v-if="group.display_name !== 'معلومات الملكية'" cols="12">
          <hr class="my-2">
        </b-col>
        <template v-for="(feature, j) in group.features">
          <b-col :id="feature.id" :key="j" cols="12"
            :lg="feature.feature.value_type === 'object' ? 12 : getDisplayProp(feature, 'lg', 6)"
            :md="feature.feature.value_type === 'object' ? 12 : getDisplayProp(feature, 'md', 6)">
            <!-- Object Type -->
            <template v-if="feature.feature.value_type === 'object'">
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <div class="d-flex">
                    <feather-icon icon="GlobeIcon" size="19" />
                    <h4 class="mb-0 ml-50">
                      {{ feature.feature.display_name }}
                      <b-button v-if="feature.feature.value_options.can_add" class="btn-icon rounded-circle btn-sm"
                        variant="primary" style="width: 14px; height: 14px; padding: 0" size="sm"
                        @click="addOwnership(feature)">
                        <feather-icon icon="PlusIcon" size="10" />
                      </b-button>
                    </h4>
                  </div>
                </b-col>
                <b-col cols="12">
                  <hr class="my-2">
                </b-col>
              </b-row>
              <b-row v-if="feature.feature.value_options.can_add">
                <b-col v-for="(item, i) in evaluationRequestData.features[getFeatureIndex(feature)].value" :id="item.id"
                  :key="i" ref="row" cols="12" md="12" lg="12">
                  <b-row>
                    <template v-for="(nestedFeature, nestedIndex) in feature.feature.value_options.fields">
                      <b-col :key="nestedIndex" cols="12" md="3" lg="3">
                        <div style="display: flex; align-items: center;">
                          <validation-provider
                            v-if="checkFieldCondition(nestedFeature, evaluationRequestData.features[getFeatureIndex(feature)].value)"
                            #default="validationContext" :vid="`${nestedFeature.name}_${j}_${nestedIndex}`"
                            :name="`${nestedFeature.name}_${j}_${nestedIndex}`"
                            :rules="`${getValidationRules(evaluationRequestData.features[getFeatureIndex(feature)].value,nestedFeature.name, nestedFeature)}`"
                            >
                            <b-form-group :label="nestedFeature.display_name + (nestedFeature.required ? ' *' : '')"
                              :label-for="`${nestedFeature.name}_${j}_${nestedIndex}`"
                              :state="getValidationState(validationContext)">
                              <b-input-group v-if="nestedFeature.value_type === 'computed'">
                                <b-form-input type="text" disabled
                                  :value="computeResult(nestedFeature.formula, nestedFeature.name, feature.feature.value_options.fields, evaluationRequestData.features[getFeatureIndex(feature)].value)"
                                  v-model="evaluationRequestData.features[getFeatureIndex(feature)].value[i][nestedFeature.name]" />
                                <b-input-group-append v-if="nestedFeature.suffix" is-text>
                                  {{ nestedFeature.suffix }}
                                </b-input-group-append>
                              </b-input-group>
                              <!-- Enum Type -->
                              <v-select v-else-if="nestedFeature.value_type === 'enum'"
                                v-model="evaluationRequestData.features[getFeatureIndex(feature)].value[i][nestedFeature.name]"
                                :close-on-select="!nestedFeature.value_options.multiple"
                                :multiple="nestedFeature.value_options.multiple"
                                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                                :options="nestedFeature.value_options.options" :clearable="true" input-id="client"
                                @input="featureChanged" />

                              <!-- Boolean Type -->
                              <b-form-radio-group v-else-if="nestedFeature.value_type === 'boolean'"
                                v-model="evaluationRequestData.features[getFeatureIndex(feature)].value[i][nestedFeature.name]"
                                :options="getOptions(nestedFeature)" class="demo-inline-spacing"
                                :name="`${nestedFeature.name}_${j}_${nestedIndex}`" />

                              <!-- Text Type -->
                              <b-form-textarea v-else-if="nestedFeature.value_type === 'text'" :id="nestedFeature.name"
                                v-model="evaluationRequestData.features[getFeatureIndex(feature)].value[i][nestedFeature.name]" />

                              <!-- Range Type -->
                              <b-form-input
                                v-else-if="nestedFeature.value_type === 'range' && nestedFeature.value_options && nestedFeature.value_options.min !== undefined && nestedFeature.value_options.max !== undefined"
                                type="number"
                                v-model="evaluationRequestData.features[getFeatureIndex(feature)].value[i][nestedFeature.name]"
                                :min="nestedFeature.value_options.min" :max="nestedFeature.value_options.max" />
                              <flat-pickr v-else-if="nestedFeature.value_type === 'range'"
                                v-model="evaluationRequestData.features[getFeatureIndex(feature)].value[i][nestedFeature.name]"
                                :config="{ mode: 'range' }"
                                class="form-control flat-picker bg-transparent border-0 shadow-none"
                                placeholder="YYYY-MM-DD" />

                              <!-- Date Type -->
                              <flat-pickr v-else-if="nestedFeature.value_type === 'date'"
                                v-model="evaluationRequestData.features[getFeatureIndex(feature)].value[i][nestedFeature.name]"
                                :config="datePickerConfig"
                                class="form-control flat-picker bg-transparent border-0 shadow-none"
                                placeholder="YYYY-MM-DD" />

                              <!-- Integer, String, Coordinates (and others not explicitly handled) -->
                              <b-form-input v-else :id="nestedFeature.name" :class="nestedFeature.name"
                                v-model="evaluationRequestData.features[getFeatureIndex(feature)].value[i][nestedFeature.name]"
                                :type="feature.feature.value_type === 'integer' ? 'number' : 'text'" />

                              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                          <b-button
                            v-if="nestedIndex === 3 && evaluationRequestData.features[getFeatureIndex(feature)].value.length > 1 && feature.feature.value_options.can_add"
                            class="btn-icon" variant="danger" size="sm"
                            style="margin-right: 10px; left: 20px; width: 18px; height: 18px; padding: 0"
                            @click="removeOwnership(i, feature)">
                            <feather-icon icon="XIcon" size="12" />
                          </b-button>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-else>
                <template v-for="(nestedFeature, nestedIndex) in feature.feature.value_options.fields">
                  <b-col :key="nestedIndex" cols="6" md="6" lg="6">
                    <validation-provider
                      v-if="checkFieldCondition(nestedFeature, evaluationRequestData.features[getFeatureIndex(feature)].value)"
                      #default="validationContext" :vid="`${nestedFeature.name}_${j}_${nestedIndex}`"
                      :name="`${nestedFeature.name}_${j}_${nestedIndex}`"
                      :rules="nestedFeature.required ? 'required' : ''">
                      <b-form-group :label="nestedFeature.display_name + (nestedFeature.required ? ' *' : '')"
                        :label-for="`${nestedFeature.name}_${j}_${nestedIndex}`"
                        :state="getValidationState(validationContext)">
                        <b-input-group v-if="nestedFeature.value_type === 'computed'">
                          <b-form-input type="text" disabled
                            :value="computeResult(nestedFeature.formula, nestedFeature.name, feature.feature.value_options.fields, evaluationRequestData.features[getFeatureIndex(feature)].value)"
                            v-model="evaluationRequestData.features[getFeatureIndex(feature)].value[nestedFeature.name]" />
                          <b-input-group-append v-if="nestedFeature.suffix" is-text>
                            {{ nestedFeature.suffix }}
                          </b-input-group-append>
                        </b-input-group>
                        <!-- Enum Type -->
                        <v-select v-else-if="nestedFeature.value_type === 'enum'"
                          v-model="evaluationRequestData.features[getFeatureIndex(feature)].value[nestedFeature.name]"
                          :close-on-select="!nestedFeature.value_options.multiple"
                          :multiple="nestedFeature.value_options.multiple"
                          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                          :options="nestedFeature.value_options.options" :clearable="true" input-id="client"
                          @input="featureChanged" />

                        <!-- Boolean Type -->
                        <b-form-radio-group v-else-if="nestedFeature.value_type === 'boolean'"
                          v-model="evaluationRequestData.features[getFeatureIndex(feature)].value[nestedFeature.name]"
                          :options="getOptions(nestedFeature)" class="demo-inline-spacing"
                          :name="`${nestedFeature.name}_${j}_${nestedIndex}`" />

                        <!-- Text Type -->
                        <b-form-textarea v-else-if="nestedFeature.value_type === 'text'" :id="nestedFeature.name"
                          v-model="evaluationRequestData.features[getFeatureIndex(feature)].value[nestedFeature.name]" />

                        <!-- Range Type -->
                        <b-form-input
                          v-else-if="nestedFeature.value_type === 'range' && nestedFeature.value_options && nestedFeature.value_options.min !== undefined && nestedFeature.value_options.max !== undefined"
                          type="number"
                          v-model="evaluationRequestData.features[getFeatureIndex(feature)].value[nestedFeature.name]"
                          :min="nestedFeature.value_options.min" :max="nestedFeature.value_options.max" />
                        <flat-pickr v-else-if="nestedFeature.value_type === 'range'"
                          v-model="evaluationRequestData.features[getFeatureIndex(feature)].value"
                          :config="{ mode: 'range' }"
                          class="form-control flat-picker bg-transparent border-0 shadow-none"
                          placeholder="YYYY-MM-DD" />

                        <!-- Date Type -->
                        <flat-pickr v-else-if="nestedFeature.value_type === 'date'"
                          v-model="evaluationRequestData.features[getFeatureIndex(feature)].value[nestedFeature.name]"
                          :config="datePickerConfig"
                          class="form-control flat-picker bg-transparent border-0 shadow-none"
                          placeholder="YYYY-MM-DD" />

                        <!-- Integer, String, Coordinates (and others not explicitly handled) -->
                        <b-form-input v-else :id="nestedFeature.name"
                          v-model="evaluationRequestData.features[getFeatureIndex(feature)].value[nestedFeature.name]"
                          :type="feature.feature.value_type === 'integer' ? 'number' : 'text'" />

                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </template>
              </b-row>
            </template>
            <template v-else>
              <validation-provider v-if="checkCondition(feature.feature)" #default="validationContext"
                :vid="feature.feature.name" :name="feature.feature.name"
                :rules="feature.feature.value_options && feature.feature.value_options.required ? 'required' : ''">
                <b-form-group :label="getFeatureLabel(group, j, feature.feature.display_name) + (feature.feature.value_options && feature.feature.value_options.required ? ' *' : '')"
                  :label-for="feature.feature.name" :state="getValidationState(validationContext)">
                  <b-input-group v-if="feature.feature.value_type === 'computed'">
                    <b-form-input type="text" disabled
                      :value="computeFieldsResult(feature.feature.value_options.formula, 'value', group.features, evaluationRequestData.features, evaluationRequestData.features[getFeatureIndex(feature)])"
                      v-model="evaluationRequestData.features[getFeatureIndex(feature)].value" />
                    <b-input-group-append v-if="feature.feature.value_options.suffix" is-text>
                      {{ feature.feature.value_options.suffix }}
                    </b-input-group-append>
                  </b-input-group>
                  <!-- Enum Type -->
                  <v-select v-else-if="feature.feature.value_type === 'enum'"
                    v-model="evaluationRequestData.features[getFeatureIndex(feature)].value"
                    :close-on-select="!feature.feature.value_options.multiple"
                    :multiple="feature.feature.value_options.multiple"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="feature.feature.value_options.options" :clearable="true" input-id="client"
                    @input="featureChanged" />

                  <!-- Boolean Type -->
                  <b-form-radio-group v-else-if="feature.feature.value_type === 'boolean'"
                    v-model="evaluationRequestData.features[getFeatureIndex(feature)].value"
                    :options="getOptions(feature.feature)" class="demo-inline-spacing" :name="feature.feature.name" />

                  <!-- Text Type -->
                  <b-form-textarea v-else-if="feature.feature.value_type === 'text'" :id="feature.feature.name"
                    v-model="evaluationRequestData.features[getFeatureIndex(feature)].value" />

                  <!-- Range Type -->
                  <b-form-input
                    v-else-if="feature.feature.value_type === 'range' && feature.feature.value_options && feature.feature.value_options.min !== undefined && feature.feature.value_options.max !== undefined"
                    type="number" v-model="evaluationRequestData.features[getFeatureIndex(feature)].value"
                    :min="feature.feature.value_options.min" :max="feature.feature.value_options.max" />
                  <flat-pickr v-else-if="feature.feature.value_type === 'range'"
                    v-model="evaluationRequestData.features[getFeatureIndex(feature)].value" :config="{ mode: 'range' }"
                    class="form-control contract-edit-input" placeholder="YYYY-MM-DD" />

                  <!-- Date Type -->
                  <flat-pickr v-else-if="feature.feature.value_type === 'date'"
                    v-model="evaluationRequestData.features[getFeatureIndex(feature)].value" :config="datePickerConfig"
                    class="form-control contract-edit-input" placeholder="YYYY-MM-DD" />

                  <!-- Integer, String, Coordinates (and others not explicitly handled) -->
                  <b-form-input v-else :id="feature.feature.name"
                    v-model="evaluationRequestData.features[getFeatureIndex(feature)].value"
                    :type="feature.feature.value_type === 'integer' ? 'number' : 'text'" />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </template>
          </b-col>
        </template>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BMedia,
  BAlert,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
  BSpinner,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, onUnmounted, onMounted } from '@vue/composition-api';
import { required, alphaNum, validEmail , validTotalEqualTo } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import evaluationRequestStore from '../../evaluationRequestStore';
import { evaluationRequestMixin } from '@/@core/mixins/modules/evaluation';
import { propertyEvaluationMixin } from '@/@core/mixins/modules/evaluation/propertyEvaluation';

export default {
  components: {
    BButton,
    BMedia,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormTextarea,
    BSpinner,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [alertMessageMixin, evaluationRequestMixin, propertyEvaluationMixin],
  props: {
    contract: {
      type: Object,
      required: false,
    },
    cost: {
      type: Object,
      required: false,
    },
    propertyType: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      required ,
      alphaNum,
      validEmail,
      validTotalEqualTo,
      datePickerConfig: {
          locale: Arabic,
          enableTime: false,
          dateFormat: "Y-m-d",
        },
    };
  },
  mounted() {
    this.$root.$on('validateOwnership', async () => {
      this.$refs.ownershipRules.validate().then((success) => {
        this.$root.$emit('ownershipValidated', { success, data: this.evaluationRequestData });
      });
    });
  },
  beforeDestroy() {
    this.$root.$off('validateOwnership');
  },
  methods: {
    addOwnership(feature) {
      // Find the index of the feature
      const featureIndex = this.getFeatureIndex(feature);
      // Check if the feature exists
      if (featureIndex !== -1) {
        // Push an empty object to the value array of the feature
        this.evaluationRequestData.features[featureIndex].value.push({});
      }
    },
    removeOwnership(index,feature) {
       // Find the index of the feature
       const featureIndex = this.getFeatureIndex(feature);
      // Check if the feature exists
      if (featureIndex !== -1) {
        // Push an empty object to the value array of the feature
        this.evaluationRequestData.features[featureIndex].value.splice(index, 1);
      }
    },
    validateRules(feature) {
      return true;
    },
    addEvaluationRequest() {
      this.$refs.refFormObserver
        .validate()
        .then(async (success) => {
          if (success) {
            this.$store
              .dispatch(
                'evaluationRequest/addEvaluationRequest',
                this.evaluationRequestData,
              )
              .then((response) => {
                this.$toast.success(response.data.message);
                router.push('/evaluation-request').catch(() => {});
              })
              .catch((err) => {
                if (err.response) {
                  if (err.response.status === 422) {
                    this.$toast.error(err.response.data.message);
                    const { message, errors } = err.response.data;
                    if (errors) {
                      const errorMessage = {
                        message: '',
                        type: 'danger',
                      };
                      Object.values(errors).forEach((error) => {
                        errorMessage.message += `${error[0]}<br>`;
                      });
                      this.displayMessage(errorMessage);
                    }
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: message,
                        text: message,
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                      },
                    });
                  } else {
                    this.$toast.error(err.response.data.error);
                  }
                }
                console.log(err);
              });
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              this.$refs.refFormObserver.setErrors(err.response.data.errors);
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
  },
  setup(props) {
    const isLoadingStart = ref(true); // Start with loading as true
    const { propertyType } = props;
    const EVALUATIONREQUEST_STORE_MODULE_NAME = 'evaluationRequest';
    const features = ref([]);
    const evaluationRequestData = ref(null);
    let blankEvaluationRequestData = {};

    // Register evaluationRequest store module
    if (!store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) {
      store.registerModule(
        EVALUATIONREQUEST_STORE_MODULE_NAME,
        evaluationRequestStore,
      );
    }

    // Unregister module when the component is unmounted
    onUnmounted(() => {
      if (store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) {
        store.unregisterModule(EVALUATIONREQUEST_STORE_MODULE_NAME);
      }
    });

    // Fetch features and stop loading when done
    onMounted(async () => {
      try {
        if (propertyType) {
          const response = await store.dispatch('evaluationRequest/fetchFeatures', { id: propertyType });
          const { data } = response.data;
          const evaluationRequestFeatures = [];

          features.value = data.groups.filter((group) =>
            ['ownership_info', 'ownership_other_info'].includes(group.name)
          ).map((group) => {
            group.features = group.features.map((feature) => {
              if (feature.feature.value_options && feature.feature.value_options.can_add) {
                // If can_add is true, initialize value as an array
                evaluationRequestFeatures.push({
                  feature_id: feature.feature.id,
                  feature_group_id: group.id,
                  value: [{}], // Initialize value as an array
                });
              } else {
                evaluationRequestFeatures.push({
                  feature_id: feature.feature.id,
                  feature_group_id: group.id,
                  value: feature.feature.value_type === 'object' ?
                    feature.feature.value_options.fields.reduce((acc, field) => {
                      acc[field.name] = null;
                      return acc;
                    }, {})
                    : feature.feature.default_value || feature.feature.value_type === 'date' ? null : "",
                });
              }

              if (feature.value_options && feature.value_options.options) {
                feature.value_options.options = feature.value_options.options.map((option) => ({
                  value: option,
                  label: option,
                  text: option,
                }));
              }

              return feature;
            });
            return group;
          });

          blankEvaluationRequestData = {
            features: evaluationRequestFeatures,
          };

          evaluationRequestData.value = blankEvaluationRequestData;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        isLoadingStart.value = false; // Stop the loader when fetching is complete
      }
    });

    const resetevaluationRequestData = () => {
      evaluationRequestData.value = JSON.parse(JSON.stringify(blankEvaluationRequestData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetevaluationRequestData);

    return {
      isLoadingStart,
      features,
      evaluationRequestData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  }

};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
